import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import AgentBalanceHistory from "./AgentBalanceHistory";
import AgentBookingTypeList from "./AgentBookingTypeList";
import AgentVoucherTypeList from "./AgentVoucherTypeList";
import AgentUserList from "./AgentUserList";

import { agentApi, bookingApi, creditMonitoringApi } from "functions/apis";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function AgentDetail(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { id } = useParams();
  const [agent, setAgent] = React.useState(null);

  const [agentCredit, setAgentCredit] = React.useState(null);
  const [agentBooking, setAgentBooking] = React.useState(null);

  useEffect(() => {
    agentApi
      .get("/Agents/" + id, {
        signal: source.current.signal
      })
      .then(res => setAgent(res))
      .catch(error => console.log(error));
  }, [id]);
  useEffect(() => {
    if (agent !== null && agent.publicationStatus !== 0) {
      creditMonitoringApi
        .get("/Agents/" + agent.id, {
          signal: source.current.signal
        })
        .then(res => setAgentCredit(res))
        .catch(error => console.log(error));

      bookingApi
        .get("/Agents/" + agent.id, {
          signal: source.current.signal
        })
        .then(res => setAgentBooking(res))
        .catch(error => console.log(error));
    }
  }, [agent]);

  const classes = useStyles();
  return (
    agent !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>DETAIL</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              {(token["agent.agents.write"] === "allowed" ||
                token.is_admin === "True") && (
                <Link to={"/admin/Agent/Edit/" + id}>
                  <Button color="rose" type="button" value="Edit">
                    EDIT
                  </Button>
                </Link>
              )}
              <CustomInput
                labelText="Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: agent.code
                }}
              />

              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: agent.name
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: agent.email === null ? "" : agent.email
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Business Category"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.businessCategory === null
                          ? ""
                          : agent.businessCategory.name
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Location"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: agent.location === null ? "" : agent.location.name
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Person"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.contactPerson === null ? "" : agent.contactPerson
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Company Address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.companyAddress === null
                          ? ""
                          : agent.companyAddress
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number (Office)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.officeContactNumber === null
                          ? ""
                          : agent.officeContactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number (Mobile)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.mobileContactNumber === null
                          ? ""
                          : agent.mobileContactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Referrer"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: agent.referrer === null ? "" : agent.referrer
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Account Manager"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.accountManager === null
                          ? ""
                          : agent.accountManager
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="First Referrer"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.firstReferrer === null ? "" : agent.firstReferrer
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          {agent.publicationStatus !== 0 && agentCredit !== null && (
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <MailOutline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>CREDIT INFORMATION</h4>
              </CardHeader>
              <CardBody>
                {(token["creditmonitoring.agents.write"] === "allowed" ||
                  token.is_admin === "True") &&
                  agent.publicationStatus === 1 && (
                    <Link to={"/admin/Agent/EditCreditInformation/" + id}>
                      <Button color="rose" type="button" value="Edit">
                        Edit
                      </Button>
                    </Link>
                  )}
                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Billing Currency"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          agentCredit.billingCurrency === null
                            ? ""
                            : agentCredit.billingCurrency.code
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Current Balance"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: agentCredit.currentBalance.toLocaleString()
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Credit Limit Balance"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: agentCredit.creditLimitBalance.toLocaleString()
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Balance Alert Threshold"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: agentCredit.balanceAlertThreshold.toLocaleString()
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          )}
          {agent.publicationStatus !== 0 &&
            agentCredit !== null &&
            agentBooking !== null && (
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <MailOutline />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    BOOKING CONFIGURATION
                  </h4>
                </CardHeader>
                <CardBody>
                  {(token["booking.agents.write"] === "allowed" ||
                    token.is_admin === "True") &&
                    agent.publicationStatus === 1 && (
                      <Link to={"/admin/Agent/EditBookingConfiguration/" + id}>
                        <Button color="rose" type="button" value="Edit">
                          Edit
                        </Button>
                      </Link>
                    )}
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={agentBooking.overrideBookingTransferFee}
                            disabled={true}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Override Booking Transfer Fee"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <CustomInput
                        labelText="Billing Currency"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            agentCredit.billingCurrency === null
                              ? ""
                              : agentCredit.billingCurrency.code
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <CustomInput
                        labelText="Booking Transfer Fee"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: agentBooking.bookingTransferFee.toLocaleString()
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <CustomInput
                        labelText="Cancellation Fee Policy Group"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: agentBooking.cancellationFeePolicyGroup?.name
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <CustomInput
                        labelText="Transfer Fee Policy Group"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: agentBooking.transferFeePolicyGroup?.name
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            )}
          {agent.publicationStatus !== 0 && agentCredit !== null && (
            <>
              {(token["creditmonitoring.agents.balance_histories"] ===
                "allowed" ||
                token.is_admin === "True") && (
                <AgentBalanceHistory
                  id={id}
                  billingCurrency={agentCredit.billingCurrency}
                />
              )}
              {(token["order.agent_voucher_types.read"] === "allowed" ||
                token.is_admin === "True") && (
                <AgentVoucherTypeList
                  id={id}
                  billingCurrency={agentCredit.billingCurrency}
                />
              )}
              {(token["booking.agent_booking_types.read"] === "allowed" ||
                token.is_admin === "True") && (
                <AgentBookingTypeList
                  id={id}
                  billingCurrency={agentCredit.billingCurrency}
                />
              )}
              <AgentUserList id={id} />
            </>
          )}
        </GridItem>
      </GridContainer>
    )
  );
}

AgentDetail.propTypes = {
  history: PropTypes.object
};
