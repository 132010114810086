import React, { useEffect, useRef } from "react";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import { orderApi } from "functions/apis";

import { formatCurrency, formatDateTime } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import { token } from "functions/Helper";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AgentVoucherTypeList(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { id, billingCurrency } = props;
  const [reload, setReload] = React.useState({});
  const [voucherTypes, setVoucherTypes] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [filter, setFilter] = React.useState({
    searchString: null,
    activeDate: null,
    currentActive: false
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    orderApi
      .get("/Agents/" + id + "/VoucherTypes", {
        signal: source.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setVoucherTypes(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  {(token["order.agent_voucher_types.write"] === "allowed" ||
                    token.is_admin === "True") && (
                    <>
                      <Button
                        color="danger"
                        className="remove"
                        onClick={() =>
                          orderApi
                            .get(`/Agents/${id}/VoucherTypes/${record.id}`, {
                              signal: source.current.signal
                            })
                            .then(data => {
                              setEdit(data);
                              setState({
                                effectiveDate: new Date(
                                  data.effectiveDate + "Z"
                                ),
                                expiryDate:
                                  data.expiryDate !== null
                                    ? new Date(data.expiryDate + "Z")
                                    : null
                              });
                            })
                            .catch(error => console.log(error))
                        }
                      >
                        <EditIcon /> EDIT
                      </Button>
                      <Button
                        color="danger"
                        className="remove"
                        onClick={() =>
                          orderApi
                            .delete(
                              "/Agents/" + id + "/VoucherTypes/" + record.id,
                              {
                                signal: source.current.signal
                              }
                            )
                            .then(() => setReload({}))
                            .catch(error => console.log(error))
                        }
                      >
                        <DeleteIcon /> DELETE
                      </Button>
                    </>
                  )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [id, pagination, filter, reload]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const handleActiveDateChanged = e =>
    setFilter({
      ...filter,
      activeDate: e === "" ? null : e
    });

  const handleCurrentActiveChanged = e =>
    setFilter({
      ...filter,
      currentActive: e.target.checked
    });

  //Edit

  const [edit, setEdit] = React.useState(null);
  const [state, setState] = React.useState({
    effectiveDate: null,
    expiryDate: null
  });
  const [disabled, setDisabled] = React.useState(false);
  const handleEffectiveDateChanged = e =>
    setState({
      ...state,
      effectiveDate: e === "" ? null : e
    });

  const handleExpiryDateChanged = e =>
    setState({
      ...state,
      expiryDate: e === "" ? null : e
    });

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    orderApi
      .put(`/Agents/${id}/VoucherTypes/${edit.id}`, state, {
        signal: source.current.signal
      })
      .then(() => {
        setReload({});
        setEdit(null);
      })
      .catch(error => console.log(error))
      .finally(() => setDisabled(false));
  };

  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>VOUCHER TYPES</h4>
      </CardHeader>
      <CardBody>
        {edit !== null && (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal
            }}
            open={edit !== null}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setEdit(null)}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setEdit(null)}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}>
                Edit {edit.voucherType.code}
              </h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classes.modalBody}
            >
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    labelText="Billing Currency"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        billingCurrency === null ? "" : billingCurrency.code
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    labelText="Billing Price"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      type: "number",
                      value: edit.billingPrice
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    labelText="Recommended Retail Price"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      type: "number",
                      value: edit.recommendedRetailPrice
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={state.effectiveDate}
                      onChange={handleEffectiveDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Effective Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={state.expiryDate}
                      onChange={handleExpiryDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Expiry Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={() => setEdit(null)} color="info" round>
                Cancel
              </Button>
              <Button
                disabled={disabled}
                onClick={handleSubmit}
                color="info"
                round
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {(token["order.agent_voucher_types.write"] === "allowed" ||
          token.is_admin === "True") && (
          <Link to={"/admin/Agent/CreateVoucherType/" + id}>
            <Button color="rose" type="button" value="Create">
              Add Voucher Type
            </Button>
          </Link>
        )}
        <GridItem xs={3}>
          <CustomInput
            labelText="Search"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: handleSearchStringChanged,
              value: filter.searchString === null ? "" : filter.searchString
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth>
            <Datetime
              value={filter.activeDate}
              onChange={handleActiveDateChanged}
              timeFormat={false}
              inputProps={{
                placeholder: "Active Date"
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={filter.currentActive}
                onChange={handleCurrentActiveChanged}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label="Current Active"
          />
        </GridItem>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={voucherTypes}
          filterable={false}
          columns={[
            {
              id: "voucherType",
              Header: "Voucher Type",
              accessor: d => d.voucherType.code
            },
            {
              id: "billingPrice",
              Header: "Billing Price",
              accessor: d =>
                formatCurrency(
                  billingCurrency === null ? "XXX" : billingCurrency.code,
                  d.billingPrice
                )
            },
            {
              id: "recommendedRetailPrice",
              Header: "Recommended Retail Price",
              accessor: d =>
                formatCurrency(
                  billingCurrency === null ? "XXX" : billingCurrency.code,
                  d.recommendedRetailPrice
                )
            },
            {
              id: "effectiveDate",
              Header: "Effective Date",
              accessor: d => formatDateTime(new Date(d.effectiveDate + "Z"))
            },
            {
              id: "expiryDate",
              Header: "Expiry Date",
              accessor: d =>
                d.expiryDate === null
                  ? ""
                  : formatDateTime(new Date(d.expiryDate + "Z"))
            },
            {
              id: "createdAt",
              Header: "Created At",
              accessor: d => formatDateTime(new Date(d.createdAt + "Z"))
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false
            }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

AgentVoucherTypeList.propTypes = {
  id: PropTypes.string,
  billingCurrency: PropTypes.object
};
